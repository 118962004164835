import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},

  actions: {
    getTermoDeUso(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/publico/empresa/termo', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    aceiteTermo() {
      return new Promise((resolve, reject) => {
        axios.put('/usuario-configuracao/termo-aceite')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
