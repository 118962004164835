<template>
  <b-modal
    id="modal-1"
    ref="my-modal"
    title="Seja bem vindo, vamos para os primeiros passos!"
    size="lg"
    :hide-footer="true"
    :hide-header-close="true"
    no-close-on-backdrop
    scrollable
  >
    <b-progress
      :value="stepProgress"
      max="100"
      variant="success"
      class="mb-1"
      height="1.5em"
      striped
      show-value
    />
    <form-wizard
      color="#28c76f"
      :title="null"
      :subtitle="null"
      shape="square"
      :finish-button-text="checkedAll ? 'Concluir' : 'Fechar sem concluir' "
      next-button-text="Avançar"
      back-button-text="Voltar"
      class="mb-3"
      @on-complete="onComplete"
    >
      <!-- Vídeo de introdução
      <tab-content
        title="Introdução"
        icon="feather icon-info"
        :before-change="beforeTabIntroSwitch"
      >
        <b-row>
          <b-col
            cols="12"
          >
            <b-embed
              type="iframe"
              aspect="16by9"
              src="https://player.vimeo.com/video/504224621"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              class="mt-1"
            />
          </b-col>
        </b-row>
      </tab-content>
      -->
      <!-- área de configuração da conta na binance - 5 vídeos -->
      <tab-content
        title="Binance"
        icon="feather icon-home"
      >
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <app-collapse
              type="border"
              accordion
            >
              <app-collapse-item
                ref="colStep1"
                title="Como criar conta na Binance"
                step="1"
                :read="boasVindascheckListPopUp['cadastroBinance']"
              >
                <p>Clique no link para o cadastro:
                  <a
                    href="https://www.binance.com/pt-BR/register?ref=18323988"
                    target="_blank"
                  >
                    https://www.binance.com/pt-BR/register?ref=18323988
                  </a>
                </p>
                <b-embed
                  type="iframe"
                  aspect="16by9"
                  src="https://player.vimeo.com/video/522135265"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                  class="mt-1"
                />
                <b-row>
                  <b-col class="text-center mt-2">
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      :variant="`outline-${boasVindascheckListPopUp['cadastroBinance'] ? 'primary' : 'success'}`"
                      class="mb-2"
                      @click="closeCollapse('colStep1', 'cadastroBinance')"
                    >
                      <feather-icon
                        v-if="!boasVindascheckListPopUp['cadastroBinance']"
                        icon="CheckIcon"
                        class="mr-50"
                      />
                      <span
                        v-if="!boasVindascheckListPopUp['cadastroBinance']"
                        class="align-middle"
                      >
                        Marcar como concluído
                      </span>
                      <span
                        v-else
                        class="align-middle"
                      >
                        Marcar como não concluído
                      </span>
                    </b-button>
                  </b-col>
                </b-row>
              </app-collapse-item>
            </app-collapse>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <app-collapse
              type="border"
              accordion
            >
              <app-collapse-item
                ref="colStep2"
                title="Verificar conta na Binance"
                step="2"
                :read="boasVindascheckListPopUp['verificacaoBinance']"
              >
                <b-embed
                  type="iframe"
                  aspect="16by9"
                  src="https://player.vimeo.com/video/528602894"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                  class="mt-1"
                />
                <b-row>
                  <b-col class="text-center mt-2">
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      :variant="`outline-${boasVindascheckListPopUp['verificacaoBinance'] ? 'primary' : 'success'}`"
                      class="mb-2"
                      @click="closeCollapse('colStep2', 'verificacaoBinance')"
                    >
                      <feather-icon
                        v-if="!boasVindascheckListPopUp['verificacaoBinance']"
                        icon="CheckIcon"
                        class="mr-50"
                      />
                      <span
                        v-if="!boasVindascheckListPopUp['verificacaoBinance']"
                        class="align-middle"
                      >
                        Marcar como concluído
                      </span>
                      <span
                        v-else
                        class="align-middle"
                      >
                        Marcar como não concluído
                      </span>
                    </b-button>
                  </b-col>
                </b-row>
              </app-collapse-item>
            </app-collapse>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <app-collapse
              type="border"
              accordion
            >
              <app-collapse-item
                ref="colStep3"
                title="Autenticação de Dois Fatores (2FA)"
                step="3"
                :read="boasVindascheckListPopUp['verificacaoDoisFatoresBinance']"
              >
                <b-embed
                  type="iframe"
                  aspect="16by9"
                  src="https://player.vimeo.com/video/527483865"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                  class="mt-1"
                />
                <b-row>
                  <b-col class="text-center mt-2">
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      :variant="`outline-${boasVindascheckListPopUp['verificacaoDoisFatoresBinance'] ? 'primary' : 'success'}`"
                      class="mb-2"
                      @click="closeCollapse('colStep3', 'verificacaoDoisFatoresBinance')"
                    >
                      <feather-icon
                        v-if="!boasVindascheckListPopUp['verificacaoDoisFatoresBinance']"
                        icon="CheckIcon"
                        class="mr-50"
                      />
                      <span
                        v-if="!boasVindascheckListPopUp['verificacaoDoisFatoresBinance']"
                        class="align-middle"
                      >
                        Marcar como concluído
                      </span>
                      <span
                        v-else
                        class="align-middle"
                      >
                        Marcar como não concluído
                      </span>
                    </b-button>
                  </b-col>
                </b-row>
              </app-collapse-item>
            </app-collapse>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <app-collapse
              type="border"
              accordion
            >
              <app-collapse-item
                ref="colStep4"
                title="Como depositar reais na Binance"
                step="4"
                :read="boasVindascheckListPopUp['depositarBinance']"
              >
                <b-embed
                  type="iframe"
                  aspect="16by9"
                  src="https://player.vimeo.com/video/527108541"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                  class="mt-1"
                />
                <b-row>
                  <b-col class="text-center mt-2">
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      :variant="`outline-${boasVindascheckListPopUp['depositarBinance'] ? 'primary' : 'success'}`"
                      class="mb-2"
                      @click="closeCollapse('colStep4', 'depositarBinance')"
                    >
                      <feather-icon
                        v-if="!boasVindascheckListPopUp['depositarBinance']"
                        icon="CheckIcon"
                        class="mr-50"
                      />
                      <span
                        v-if="!boasVindascheckListPopUp['depositarBinance']"
                        class="align-middle"
                      >
                        Marcar como concluído
                      </span>
                      <span
                        v-else
                        class="align-middle"
                      >
                        Marcar como não concluído
                      </span>
                    </b-button>
                  </b-col>
                </b-row>
              </app-collapse-item>
            </app-collapse>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <app-collapse
              type="border"
              accordion
            >
              <app-collapse-item
                ref="colStep5"
                title="Como criar uma API na Binance"
                step="5"
                :read="boasVindascheckListPopUp['criarAPIBinance']"
              >
                <b-embed
                  type="iframe"
                  aspect="16by9"
                  src="https://player.vimeo.com/video/692703181"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                  class="mt-1"
                />
                <b-row>
                  <b-col class="text-center mt-2">
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      :variant="`outline-${boasVindascheckListPopUp['criarAPIBinance'] ? 'primary' : 'success'}`"
                      class="mb-2"
                      @click="closeCollapse('colStep5', 'criarAPIBinance')"
                    >
                      <feather-icon
                        v-if="!boasVindascheckListPopUp['criarAPIBinance']"
                        icon="CheckIcon"
                        class="mr-50"
                      />
                      <span
                        v-if="!boasVindascheckListPopUp['criarAPIBinance']"
                        class="align-middle"
                      >
                        Marcar como concluído
                      </span>
                      <span
                        v-else
                        class="align-middle"
                      >
                        Marcar como não concluído
                      </span>
                    </b-button>
                  </b-col>
                </b-row>
              </app-collapse-item>
            </app-collapse>
          </b-col>
        </b-row>
      </tab-content>
      <!-- Área de configuração -->
      <tab-content
        title="Plataforma"
        icon="feather icon-settings"
      >
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <app-collapse
              type="border"
              accordion
            >
              <app-collapse-item
                ref="colStep6"
                title="Adicionando API"
                step="6"
                :read="boasVindascheckListPopUp['configurarAPICryptoboot']"
              >
                <b-embed
                  type="iframe"
                  aspect="16by9"
                  src="https://player.vimeo.com/video/651163459"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                  class="mt-1"
                />
                <b-row>
                  <b-col class="text-center mt-2">
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      :variant="`outline-${boasVindascheckListPopUp['configurarAPICryptoboot'] ? 'primary' : 'success'}`"
                      class="mb-2"
                      @click="closeCollapse('colStep6', 'configurarAPICryptoboot')"
                    >
                      <feather-icon
                        v-if="!boasVindascheckListPopUp['configurarAPICryptoboot']"
                        icon="CheckIcon"
                        class="mr-50"
                      />
                      <span
                        v-if="!boasVindascheckListPopUp['configurarAPICryptoboot']"
                        class="align-middle"
                      >
                        Marcar como concluído
                      </span>
                      <span
                        v-else
                        class="align-middle"
                      >
                        Marcar como não concluído
                      </span>
                    </b-button>
                  </b-col>
                </b-row>
              </app-collapse-item>
            </app-collapse>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <app-collapse
              type="border"
              accordion
            >
              <app-collapse-item
                ref="colStep7"
                title="Distribuindo o saldo"
                step="7"
                :read="boasVindascheckListPopUp['distribuirSaldo']"
              >
                <b-embed
                  type="iframe"
                  aspect="16by9"
                  src="https://player.vimeo.com/video/534297294"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                  class="mt-1"
                />
                <b-row>
                  <b-col class="text-center mt-2">
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      :variant="`outline-${boasVindascheckListPopUp['distribuirSaldo'] ? 'primary' : 'success'}`"
                      class="mb-2"
                      @click="closeCollapse('colStep7', 'distribuirSaldo')"
                    >
                      <feather-icon
                        v-if="!boasVindascheckListPopUp['distribuirSaldo']"
                        icon="CheckIcon"
                        class="mr-50"
                      />
                      <span
                        v-if="!boasVindascheckListPopUp['distribuirSaldo']"
                        class="align-middle"
                      >
                        Marcar como concluído
                      </span>
                      <span
                        v-else
                        class="align-middle"
                      >
                        Marcar como não concluído
                      </span>
                    </b-button>
                  </b-col>
                </b-row>
              </app-collapse-item>
            </app-collapse>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <app-collapse
              type="border"
              accordion
            >
              <app-collapse-item
                ref="colStep8"
                title="Configurando o agente"
                step="8"
                :read="boasVindascheckListPopUp['configurarAgente']"
              >
                <b-embed
                  type="iframe"
                  aspect="16by9"
                  src="https://player.vimeo.com/video/660310915"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                  class="mt-1"
                />
                <b-row>
                  <b-col class="text-center mt-2">
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      :variant="`outline-${boasVindascheckListPopUp['configurarAgente'] ? 'primary' : 'success'}`"
                      class="mb-2"
                      @click="closeCollapse('colStep8', 'configurarAgente')"
                    >
                      <feather-icon
                        v-if="!boasVindascheckListPopUp['configurarAgente']"
                        icon="CheckIcon"
                        class="mr-50"
                      />
                      <span
                        v-if="!boasVindascheckListPopUp['configurarAgente']"
                        class="align-middle"
                      >
                        Marcar como concluído
                      </span>
                      <span
                        v-else
                        class="align-middle"
                      >
                        Marcar como não concluído
                      </span>
                    </b-button>
                  </b-col>
                </b-row>
              </app-collapse-item>
            </app-collapse>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <app-collapse
              type="border"
              accordion
            >
              <app-collapse-item
                ref="colStep9"
                title="Notificações no Telegram"
                step="9"
                :read="boasVindascheckListPopUp['configurarNotificacao']"
              >
                <b-embed
                  type="iframe"
                  aspect="16by9"
                  src="https://player.vimeo.com/video/654310402"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                  class="mt-1"
                />
                <b-row>
                  <b-col class="text-center mt-2">
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      :variant="`outline-${boasVindascheckListPopUp['configurarNotificacao'] ? 'primary' : 'success'}`"
                      class="mb-2"
                      @click="closeCollapse('colStep9', 'configurarNotificacao')"
                    >
                      <feather-icon
                        v-if="!boasVindascheckListPopUp['configurarNotificacao']"
                        icon="CheckIcon"
                        class="mr-50"
                      />
                      <span
                        v-if="!boasVindascheckListPopUp['configurarNotificacao']"
                        class="align-middle"
                      >
                        Marcar como concluído
                      </span>
                      <span
                        v-else
                        class="align-middle"
                      >
                        Marcar como não concluído
                      </span>
                    </b-button>
                  </b-col>
                </b-row>
              </app-collapse-item>
            </app-collapse>
          </b-col>
        </b-row>

      </tab-content>
    </form-wizard>
  </b-modal>
</template>

<script>
/* eslint-disable */
import {
  BCard,
  BCardText,
  BLink,
  BModal,
  BRow,
  BCol,
  BProgress,
  BEmbed,
  BButton,
  BContainer,
} from "bootstrap-vue";
import store from '@/store'
import { FormWizard, TabContent } from "vue-form-wizard"
import "vue-form-wizard/dist/vue-form-wizard.min.css"
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue"
import AppCollapseItem from "@/@core/components/app-collapse/AppCollapseItemCustom.vue"
import Ripple from "vue-ripple-directive"

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BModal,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BProgress,
    BEmbed,
    BButton,
    BContainer,
    AppCollapse,
    AppCollapseItem,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      boasVindascheckListPopUp: {
        introducao: true,
				cadastroBinance: false,
				verificacaoBinance:  false,
        verificacaoDoisFatoresBinance: false,
        depositarBinance: false,
        criarAPIBinance: false,
        configurarAPICryptoboot: false,
        distribuirSaldo: false,
        configurarAgente: false,
        configurarNotificacao: false,
			},
    }
  },

  computed: {

    stepProgress: function () {
      let checked = 0
      let total = Object.keys(this.boasVindascheckListPopUp).length-1 // tirando a introdução

      Object.keys(this.boasVindascheckListPopUp).forEach( key => {
        if ( this.boasVindascheckListPopUp[key] )
          checked++
      })

      return checked/total*100
    },

    checkedAll: function () {
      const self = this
      let temp = true
      Object.keys(self.boasVindascheckListPopUp).forEach( key => {
        if ( self.boasVindascheckListPopUp[key] != true ){
          temp =  false
        }
      })
      return temp
    }
  },

  methods: {

    setCheckList() {
      this.$store.dispatch('usuario-spot/setCheckList', this.boasVindascheckListPopUp).then()
		},

    getCheckList() {

      store
        .dispatch('usuario-spot/getCheckList')
        .then(response => {
          this.boasVindascheckListPopUp = response.data
          this.boasVindascheckListPopUp['introducao'] = true // desativado
          if (!this.checkedAll) {
            this.showModal()
          }
        })
		},

    showModal() {
      this.$refs["my-modal"].show()
    },

    closeModal() {
      this.$refs["my-modal"].hide()
    },

    closeCollapse(ref, keyChecklist) {
      if ( !this.boasVindascheckListPopUp[keyChecklist] ){
        this.boasVindascheckListPopUp[keyChecklist] = true
      }
      else {
        this.boasVindascheckListPopUp[keyChecklist] = false
      }
      this.setCheckList()
      this.$refs[ref].collapseClose()
    },

    beforeTabIntroSwitch(){
      if ( !this.boasVindascheckListPopUp['introducao'] ) {
        this.boasVindascheckListPopUp['introducao'] = true
        this.setCheckList()
      }
      return true
    },

    onComplete: function() {

      if (this.checkedAll) {

        this.closeModal()
      } else {

        this.$swal({
          title: 'Tem certeza que deseja fechar?',
          text: `É muito importante que assista todos os vídeos`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim, vou assistir mais tarde',
          cancelButtonText: 'Não, vou assistir agora',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1 mt-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.closeModal()
          }
        })
      }
    },
  },

  mounted() {
    this.getCheckList()
  },

}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import '@core/scss/vue/libs/vue-sweetalert.scss';

@media (min-width: 992px) {
  .modal-lg {
    max-width: auto !important;
  }
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: auto !important;
  }
}
</style>