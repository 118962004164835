<template>
  <div class="tradingview-widget-container">
    <div
      ref="widgetContainer"
      class="tradingview-widget-container__widget"
    />
  </div>
</template>

<script>

export default {
  name: 'TradingViewWidgets',

  props: {
    options: {
      default: [],
    },
  },

  watch: {
    'options.theme': 'updateTradingViewWidget',
  },

  mounted() {
    this.setupTradingViewWidget()
  },

  methods: {
    setupTradingViewWidget() {
      const container = this.$refs.widgetContainer
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-tickers.js'
      script.async = true
      script.text = JSON.stringify({
        symbols: [
          { proName: 'FOREXCOM:SPXUSD', title: 'S&P 500' },
          { proName: 'FOREXCOM:NSXUSD', title: 'US 100' },
          { proName: 'FX_IDC:EURUSD', title: 'EUR para USD' },
          { proName: 'BITSTAMP:BTCUSD', title: 'Bitcoin' },
          { proName: 'BITSTAMP:ETHUSD', title: 'Ethereum' },
        ],
        isTransparent: true,
        showSymbolLogo: true,
        colorTheme: this.options.theme,
        locale: 'en',
      })

      // Adiciona o script à container
      container.appendChild(script)
    },

    updateTradingViewWidget() {
      // Remove todos os filhos do container
      const container = this.$refs.widgetContainer
      while (container.firstChild) {
        container.removeChild(container.firstChild)
      }

      // Configura o novo widget
      this.setupTradingViewWidget()
    },
  },
}
</script>

<style scoped>
</style>
