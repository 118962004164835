<template>
  <b-card
    no-body
    :class="{'open': visible}"
  >
    <b-card-header
      :class="[{'collapsed': !visible}, read ? 'text-success' : 'text-primary']"
      :aria-expanded="visible ? 'true' : 'false'"
      :aria-controls="collapseItemID"
      role="tab"
      data-toggle="collapse"
      @click="updateVisible(!visible)"
    >
      <slot name="header">
        <b-container>
          <b-row name="step">
            <b-col
              cols="12"
              sm="1"
            ><b-badge
              :variant="`light-${read ? 'success' : 'primary'}`"
              pill
            >
              {{ step }}
            </b-badge>
            </b-col>
            <b-col
              cols="12"
              sm="10"
            >
              <span class="lead collapse-title">{{ title }} </span>
            </b-col>
            <b-col
              cols="12"
              sm="1"
            >
              <feather-icon
                v-if="windowWidth>=576"
                :icon="read ? 'ThumbsUpIcon' : 'AlertCircleIcon'"
                size="24"
              />
            </b-col>
          </b-row>
        </b-container>
      </slot>
    </b-card-header>

    <b-collapse
      :id="collapseItemID"
      v-model="visible"
      :accordion="accordion"
      role="tabpanel"
    >
      <b-card-body>
        <slot />
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCollapse, BBadge, BRow, BCol, BContainer,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BBadge,
    BRow,
    BCol,
    BContainer,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    step: {
      type: String,
      required: true,
    },
    read: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      collapseItemID: '',
    }
  },
  computed: {
    accordion() {
      return this.$parent.accordion ? `accordion-${this.$parent.collapseID}` : null
    },
    windowWidth() {
      return this.$store.state.app.windowWidth
    },
  },
  created() {
    this.collapseItemID = uuidv4()
    this.visible = this.isVisible
  },
  methods: {
    updateVisible(val) {
      this.visible = val
      this.$emit('visible', val)
    },
    collapseOpen() {
      this.updateVisible(true)
    },
    collapseClose() {
      this.updateVisible(false)
    },
  },
}
</script>
