<template>
  <b-overlay
    :show="loadingOverlayCarteira"
    rounded="sm"
  >
    <b-modal
      id="modal-termo"
      ref="modal-termo"
      scrollable
      title="Aviso Legal e Termo de Responsabilidade"
      cancel-title="Não estou de acordo"
      ok-title="Li e estou de acordo"
      cancel-variant="secondary"
      ok-variant="success"
      :hide-header-close="true"
      no-close-on-backdrop
      @cancel="naoAceito"
      @ok.prevent="aceito"
    >
      <b-card-text v-html="content" />
    </b-modal>
  </b-overlay>
</template>

<script>

import {
  BCardText,
  BModal,
  BOverlay,
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
import termoStoreModule from './termoStoreModule'

const TERMO_USO_STORE_MODULE_NAME = 'termo-uso'

export default {

  components: {
    BCardText,
    BModal,
    BOverlay,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      content: '',
      loadingOverlayTermo: false,
    }
  },

  computed: {
  },

  created() {
    if (!store.hasModule(TERMO_USO_STORE_MODULE_NAME)) store.registerModule(TERMO_USO_STORE_MODULE_NAME, termoStoreModule)
  },

  beforeDestroy() {
    if (store.hasModule(TERMO_USO_STORE_MODULE_NAME)) store.unregisterModule(TERMO_USO_STORE_MODULE_NAME)
  },

  mounted() {
    this.getTermoUso()
  },

  methods: {

    naoAceito() {
      document.getElementById('logout').click()
    },

    aceito() {
      this.loadingOverlayTermo = true

      store
        .dispatch('termo-uso/aceiteTermo')
        .then(() => {
          let dataUser = localStorage.getItem('userData')
          dataUser = dataUser ? JSON.parse(dataUser) : {}
          dataUser.termoAceito = true
          localStorage.setItem('userData', JSON.stringify(dataUser))
          this.$refs['modal-termo'].hide()
        }).catch(() => {
          this.error = 'Ocorreu um problema, tente novamente.'
        }).finally(() => {
          this.$refs['modal-termo'].hide()
          this.loadingOverlayTermo = false
        })
    },

    getTermoUso() {
      if (!getUserData().termoAceito) {
        store
          .dispatch('termo-uso/getTermoDeUso', { dominio: window.location.host })
          .then(response => {
            this.content = response.data
            this.showModal()
          })
      }
    },

    showModal() {
      this.$refs['modal-termo'].show()
    },

    closeModal() {
      this.$refs['modal-termo'].hide()
    },
  },

}
</script>

<style lang="scss">

@media (min-width: 992px) {
  .modal-lg {
    max-width: auto !important;
  }
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: auto !important;
  }
}
</style>
